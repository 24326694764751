/* Mandant 2 */
@font-face {
  font-family: "Circular";
  src:
    url("/assets/fonts/lineto-circular-pro-book.woff2") format("woff2"), url("/assets/fonts/lineto-circular-pro-book.woff") format("woff");;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular";
  src:
    url("/assets/fonts/lineto-circular-pro-bold.woff2") format("woff2"), url("/assets/fonts/lineto-circular-pro-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Circular";
  src:
    url("/assets/fonts/lineto-circular-pro-black.woff2") format("woff2"), url("/assets/fonts/lineto-circular-pro-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'thalia_vis_numbers';
  src:
    url("/assets/fonts/thalia_vis_numbers.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Lumios";
  src:
    url("/assets/fonts/LumiosforThalia-Marker.woff2") format("woff2"), url("/assets/fonts/LumiosforThalia-Marker.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Mandant 5 */
@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* Mandant 37 */
@font-face {
  font-family: "Gill Sans W04";
  src: url("/assets/fonts/LatoLatin-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gill Sans W04";
  src: url("/assets/fonts/LatoLatin-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LatoLatin";
  src: url("/assets/fonts/LatoLatin-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LatoLatin";
  src: url("/assets/fonts/LatoLatin-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
