dialog::backdrop {
	background: rgba(0, 0, 0, .45);
}

dialog+.backdrop {
	background: rgba(0, 0, 0, 0.45);
}

.dialog-open {
	position: relative;
	overflow: hidden;
	padding-right: 16px;
	/* IE11 */
	padding-right: var(--scrollbar-width);
	/* Modern Browser, set dynamically in JS */
}
